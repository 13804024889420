export const environment = {
  production: false,
  targetAPI: 'https://customer-middleware.afrikpay.com/',
  agentid : '3587588058267416',
  agentpwd : '3587@7416$',
  apikey : '9c17aea333baede307bb61c98b27b51d',
  url : 'https://instanttransfer.afrikpay.com',
  id : 'instanttransfer.afrikpay.com',
  accepturl : 'https://instanttransfer.afrikpay.com/payment/web/success',
  cancelurl : 'https://instanttransfer.afrikpay.com/payment/web/failed',
  printurl : "http://invoice.afrikpay.com/print/",
  storage : 'home/oss'
};